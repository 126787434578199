/**
 * Navigation Panel Component
 *
 * Main navigation that opens when the hamburger menu is pressed.
 * Allows users to navigate to different sections of the hunt without
 * going back to the main overview page. Shows and hides different menu items based on hunt settings
 */

import React from 'react'
import Link from '../link'
import { getCurrentUser } from '../../utils/auth'
import Logout from '../logout'
import { getHuntStatus, isPermanentCode } from '../../utils/team-data'
import { getSiteData } from '../../utils/site'
import AppContext from '../../utils/context'
import { getCurrentHuntData } from '../../utils/hunt-data'

class NavPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      read_rules: false,
      user: undefined,
      hunt_state: undefined,
    }
    this.update = this.update.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.toggleNav() // Toggle nav
    }
  }

  componentDidMount() {
    this.update()
    this.interval = setInterval(this.update, 1 * 1000)
    document.addEventListener(`keydown`, this.escFunction, false)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    document.removeEventListener(`keydown`, this.escFunction, false)
  }

  update() {
    this.setState({
      hunt_state: getHuntStatus(),
      data: getCurrentHuntData(),
      user: getCurrentUser(),
    })
  }

  render() {
    const { data, hunt_state } = this.state
    const is_permanent = isPermanentCode()
    const show_questions = hunt_state == `started`
    const hunt_type = data.hunt !== undefined && data.hunt.hunt_type !== undefined ? data.hunt.hunt_type : 0
    const isGnG = hunt_type == 1
    const show_leaderboard =
      data.hunt !== undefined && data.hunt.enable_leaderboard !== undefined ? data.hunt.enable_leaderboard : false
    const show_contact =
      data.hunt !== undefined && data.hunt.enable_contact_page !== undefined
        ? data.hunt.enable_contact_page && hunt_state !== `ended`
        : false

    return (
      <AppContext.Consumer>
        {({ navOpen, toggleNav, enable_photos, site_data }) => {
          this.toggleNav = toggleNav
          return (
            <div className="site-menu site-menu-nav" role="menu" aria-expanded={`${navOpen ? `true` : `false`}`}>
              <nav>
                <div className={`px-4 mt-4`}>
                  <button
                    className={`px-4 py-3 btn btn-link menu-toggle ${navOpen ? `nav-is-active` : ``}`}
                    onClick={toggleNav}
                    title="Close Menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                      <g fill="none" fillRule="evenodd" stroke="#273A41" strokeLinecap="round" strokeWidth="2">
                        <path d="M1.5 1.5l16.867 16.867M18.5 1.5L1.633 18.367" />
                      </g>
                    </svg>
                  </button>
                </div>
                <ul className={`list-unstyled mt-2 px-5 list-menu`}>
                  {hunt_state !== `ended` && (
                    <li>
                      <Link to="/">Your Game</Link>
                    </li>
                  )}

                  {hunt_state !== `ended` && (
                    <li>
                      <Link to="/rules/">How to Play</Link>
                    </li>
                  )}

                  {show_questions && (
                    <>
                      {/* Define default menu items */}
                      {(() => {
                        const defaultMenuItems = [
                          {
                            key: 'questions',
                            condition: data.questions && data.questions.length > 0,
                            content: (
                              <li key="questions">
                                <Link to={isGnG ? `/questions/overview/` : `/questions/`}>
                                  {data.hunt.questions_title || 'Questions'}
                                </Link>
                              </li>
                            ),
                          },
                          {
                            key: 'answer_sheet',
                            condition: data.hunt.enable_answer_sheet,
                            content: (
                              <li key="answer_sheet">
                                <Link to={`/answers/`}>{data.hunt.answer_sheet_title || 'Team Answer Sheet'}</Link>
                              </li>
                            ),
                          },
                          {
                            key: 'checklists',
                            condition: data.checklists && data.checklists.length > 0,
                            content: data.checklists.map((checklist) => (
                              <li key={`checklist-${checklist.id}`}>
                                <Link to={`/checklists/#${checklist.id}`}>{checklist.display_title}</Link>
                              </li>
                            )),
                          },
                          {
                            key: 'bonus_challenge',
                            condition: data.hunt.enable_bonus_challenge,
                            content: (
                              <li key="bonus_challenge">
                                <Link to={`/bonus-challenge/`}>
                                  {data.hunt.bonus_challenge_title || 'Bonus Challenge'}
                                </Link>
                              </li>
                            ),
                          },
                          {
                            key: 'final_questions',
                            condition: data.secondary_questions && data.secondary_questions.length > 0,
                            content: (
                              <li key="final_questions">
                                <Link to={`/questions/final/`}>
                                  {data.hunt.secondary_questions_title || 'Final Questions'}
                                </Link>
                              </li>
                            ),
                          },
                          {
                            key: 'emergency_message',
                            condition: data.emergency_message,
                            content: (
                              <li key="emergency_message">
                                <Link to={`/emergency-message/`}>
                                  {data.emergency_message && data.emergency_message.display_title
                                    ? data.emergency_message.display_title
                                    : `Emergency Message`}
                                </Link>
                              </li>
                            ),
                          },

                          {
                            key: 'mystery_message',
                            condition: data.mystery_message,
                            content: (
                              <li key="mystery_message">
                                <Link to={`/mystery-message/`}>
                                  {data.hunt.mystery_message_title
                                    ? data.hunt.mystery_message_title
                                    : `Mystery Message`}
                                </Link>
                              </li>
                            ),
                          },
                        ]

                        // Sort menu items based on data.hunt.menu_order
                        const sortedMenuItems =
                          data.hunt.menu_order && Object.keys(data.hunt.menu_order).length > 0
                            ? defaultMenuItems.sort(
                                (a, b) =>
                                  (data.hunt.menu_order[a.key] || Number.MAX_SAFE_INTEGER) -
                                  (data.hunt.menu_order[b.key] || Number.MAX_SAFE_INTEGER)
                              )
                            : defaultMenuItems; // Fallback to default order if no menu_order exists

                        // Render sorted menu items
                        return sortedMenuItems.map(
                          (item) =>
                            item.condition && (
                              <React.Fragment key={item.key}>
                                {item.content}
                              </React.Fragment>
                            )
                        );
                      })()}
                    </>
                  )}

                  
                  {!is_permanent && enable_photos && hunt_state != `pending` && (
                    <li>
                      <Link to="/team-gallery/">Team Photo Gallery</Link>
                    </li>
                  )}

                  {/* <li>
                  <Feedback />
                </li> */}

                  {show_leaderboard && (
                    <li>
                      <Link to="/leaderboard/">Leaderboard</Link>
                    </li>
                  )}
                  {show_contact && (
                    <li>
                      <Link to={`/contact/`}>
                        {data.hunt.contact_page_title ? data.hunt.contact_page_title : `Contact Host`}
                      </Link>
                    </li>
                  )}
                  {show_questions && (
                    <li>
                      <Link to="/finish/">Done?</Link>
                    </li>
                  )}
                  {hunt_state == `ended` && (
                    <li>
                      <Link to="/finish/">Results</Link>
                    </li>
                  )}
                  <li>
                    <Logout />
                  </li>
                </ul>
              </nav>

              <div className={`copyright py-1 text-center text-uppercase`}>
                <div dangerouslySetInnerHTML={{ __html: site_data.copyright_text }} />
                {site_data.terms_headline && (
                  <small>
                    <a className="d-block text-white" style={{ textDecoration: `underline` }} href="/terms" target="_blank" rel="noopener noreferer">
                      {site_data.terms_headline}
                    </a>
                  </small>
                )}
              </div>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
export default NavPanel
